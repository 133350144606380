import React, { Component } from 'react';
import NotefulContext from '../NotefulContext';
import ValidationError from '../ValidationError';

export default class AddFolder extends Component {
    static contextType = NotefulContext;
    constructor(props) {
        super(props);
        this.state = {
            folderName: {
                value: '',
                touched: false
            }
        }
    }
    handleChange = e => {
        this.setState({
            folderName: {value: e.target.value, touched: true}
        });
    }
    validateName() {
        const folderName = this.state.folderName;
        if (folderName.value.length === 0) {
            return 'Folder Name is required';
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const name = event.target.addfolder.value;
        let newFolder = {
            name: name, 
        }
        fetch(`https://fatidique-croissant-86011.herokuapp.com/api/folders`, {
            method: 'POST',
            body: JSON.stringify(newFolder),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (!res.ok) {
              return res.json().then(error => Promise.reject(error))
            }
            return res.json()
          })
          .then(responseJson => {
            console.log(responseJson)
            this.context.updateFolders(responseJson)
            this.props.history.push('/');
        }).catch(error => {
            console.error(error)
        });
    }
    render() {
        
        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <fieldset>
                    <label htmlFor="addfolder">Folder Name</label>
                    <input name="addfolder" id="addfolder" onChange={(e) => this.handleChange(e)} defaultValue='' ></input>
                    {this.state.folderName.touched && (<ValidationError message={this.validateName()}/>)}
                </fieldset>
                <button type="submit" disabled={this.validateName()}>Add</button>
            </form>
        )
    }
}