import React, { Component } from 'react';
import NotefulContext from '../NotefulContext';
import ValidationError from '../ValidationError';

export default class AddNote extends Component {
    static contextType = NotefulContext;
    constructor(props) {
        super(props);
        this.state = {
            name: {
                value: '',
                touched: false
            },
            content: {
                value: '',
                touched: false
            },
            folderid: {
                value: '',
                touched: false
            }
        }
    }
    validateName() {
        const name = this.state.name;
        if (name.value.length === 0) {
            return 'Name is required';
        } 
    }
    validateContent() {
        const content = this.state.content;
        if (content.value.length === 0) {
            return 'Content is required';
        }
    }
    validateFolderId() {
        const folderid = this.state.folderid;
        if (folderid.value === '' || folderid.value === '--Select a folder--') {
            return 'You must select a folder from the dropdown menu.';
        }
    }
    handleNameChange(e) {
        this.setState({
            name: {value: e.target.value, touched: true}
        })
    }
    handleContentChange(e) {
        this.setState({
            content: {value: e.target.value, touched: true}
        })
    }
    handleFolderIdChange(e) {
        this.setState({
            folderid: {value: e.target.value, touched: true}
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const newNote = {
            name: this.state.name.value,
            content: this.state.content.value,
            folderid: this.state.folderid.value
        }
        fetch(`https://fatidique-croissant-86011.herokuapp.com/api/notes`, {
            method: 'POST',
            body: JSON.stringify(newNote),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if(!res.ok) {
                return res.json().then(error => Promise.reject(error))
            }
            return res.json()
        })
        .then(responseJson => {
            console.log(responseJson);
            this.context.updateNotes(responseJson);
            this.props.history.push('/');
        })
        .catch(error => {
            console.error(error);
        })
    }
    render() {
        
        return (
            <div className="addnote_main">
                <section onClick={() => this.props.history.goBack()}>Go Back</section>
                <form onSubmit={(e) => this.handleSubmit(e)} className='addnote'>
                    <label htmlFor="name">Name</label>
                    <input name="name" id="name" type="text" defaultValue='' onChange={(e) => this.handleNameChange(e)}/>
                    {this.state.name.touched && (<ValidationError message={this.validateName()}/>)}

                    <label htmlFor="content">Content</label>
                    <input name="content" id="name" type="text" defaultValue='' onChange={(e) => this.handleContentChange(e)}></input>
                    {this.state.content.touched && (<ValidationError message={this.validateContent()}/>)}

                    <label htmlFor="folder">Select Folder</label>
                    <select name="folder" id="folder" type="text" onChange={(e) => this.handleFolderIdChange(e)}>
                        <option selected="selected">--Select a folder--</option>
                        {this.context.folders ? this.context.folders.map((item, index) => {
                            return <option key={index} value={item.id}>{item.name}</option>
                        }) : <option value="0">Blank</option>}
                    </select>
                    {this.state.folderid.touched && (<ValidationError message={this.validateFolderId()}/>)}

                    <button type="submit" disabled={this.validateName() || this.validateContent() || this.validateFolderId()}>Add Note</button>
                </form>
            </div>
        )
    }
}